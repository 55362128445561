// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  width: 400px;
  margin: 40px auto;
  padding: 20px;
  border :1px solid black;
  background-color: white;
}

.toggle {
  display: flex;
  justify-content: space-between;
}

.toggle button {
  flex: 1 1;
  padding: 10px;
  border: none;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 16px;
  height: 40px;
}

.toggle .active {
  background-color: #ff3131;
  color: white;
}

.form {
  margin-top: 20px;
}

.form h2 {
  text-align: center;
}

.form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form button {
  width: 100%;
  padding: 10px;
  background-color: #ff3131;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Authenticator.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".auth-container {\n  width: 400px;\n  margin: 40px auto;\n  padding: 20px;\n  border :1px solid black;\n  background-color: white;\n}\n\n.toggle {\n  display: flex;\n  justify-content: space-between;\n}\n\n.toggle button {\n  flex: 1;\n  padding: 10px;\n  border: none;\n  background-color: #f1f1f1;\n  cursor: pointer;\n  font-size: 16px;\n  height: 40px;\n}\n\n.toggle .active {\n  background-color: #ff3131;\n  color: white;\n}\n\n.form {\n  margin-top: 20px;\n}\n\n.form h2 {\n  text-align: center;\n}\n\n.form input {\n  display: block;\n  width: 100%;\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.form button {\n  width: 100%;\n  padding: 10px;\n  background-color: #ff3131;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
