// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: white;
  padding: 0px;
  /* Other styles */




}

@media (max-width: 768px) {
  .logo {
    /* Hide desktop menu on smaller screens */
    /* display: none; */
    max-width: 250px;
    margin-top: 15px;
  }


  .header {
    min-height: 100px;
  }


}

.logo {
  max-height: 100px;

}

.logo-div {
  display: flex;
  justify-content: center;
  padding: 7px;
}

@media (max-width: 425px) {
  .logo {
    /* Hide desktop menu on smaller screens */
    /* display: none; */
    max-width: 250px;
    margin-top: 15px;
    margin-left: -145px;

  }


  .header {
    min-height: 100px;
  }


}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;;;;;AAKnB;;AAEA;EACE;IACE,yCAAyC;IACzC,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;EAClB;;;EAGA;IACE,iBAAiB;EACnB;;;AAGF;;AAEA;EACE,iBAAiB;;AAEnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE;IACE,yCAAyC;IACzC,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;;EAErB;;;EAGA;IACE,iBAAiB;EACnB;;;AAGF","sourcesContent":[".header {\n  background-color: white;\n  padding: 0px;\n  /* Other styles */\n\n\n\n\n}\n\n@media (max-width: 768px) {\n  .logo {\n    /* Hide desktop menu on smaller screens */\n    /* display: none; */\n    max-width: 250px;\n    margin-top: 15px;\n  }\n\n\n  .header {\n    min-height: 100px;\n  }\n\n\n}\n\n.logo {\n  max-height: 100px;\n\n}\n\n.logo-div {\n  display: flex;\n  justify-content: center;\n  padding: 7px;\n}\n\n@media (max-width: 425px) {\n  .logo {\n    /* Hide desktop menu on smaller screens */\n    /* display: none; */\n    max-width: 250px;\n    margin-top: 15px;\n    margin-left: -145px;\n\n  }\n\n\n  .header {\n    min-height: 100px;\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
