// const API = 'http://localhost:8080/api';
// const API = 'http://35.182.240.208:8080/api'
const API = 'https://api.safesportforyouth.org/api'
// const API = 'https://safesport-backend.onrender.com/api'
const videoData = [
    {
      titleEng: "Introduction",
      descriptionEng: "Safe Sport for Youth Intro - We will explain how these educational videos will flow, what we hope and trust you will learn from them, and set you up for success in sport.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_00_Intro_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID00ENG.png",
      titleFre: "Introduction",
      descriptionFre: "Intro pour Safe Sport pour les Jeunes - Nous expliquerons comment ces vidéos éducatives seront organisées, ce que nous espérons et croyons que vous apprendrez d'elles, et vous préparerons pour réussir dans le sport.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_00_Intro_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID00FRE.png",
    },
    {
      titleEng: "Bullying",
      descriptionEng: "We've all heard of bullying, but it may show up differently in sport than at school or other settings. Let's look at how bullying can be a reality in sport today and equip ourselves with the knowledge and support available to stop bullying in its tracks.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_01_Bullying_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID01ENG.png",
      titleFre: "Intimidation",
      descriptionFre: "Nous avons tous entendu parler de l'intimidation, mais elle peut se manifester différemment dans le sport qu'à l'école ou dans d'autres contextes. Voyons comment l'intimidation peut être une réalité dans le sport aujourd'hui et équipons-nous des connaissances et du soutien disponibles pour arrêter l'intimidation sur place.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_01_Bullying_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID01FRE.png",
    },
    {
      titleEng: "Hazing",
      descriptionEng: "Often masked as tradition, hazing is sometimes hard to identify. Just because it's happened in the past, doesn't make it ok today. This video helps us to identify what hazing is, and if it is happening to you. We provide solutions to ending any tolerance for hazing. It's not ok.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_02_Hazing_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID02ENG.png",
      titleFre: "Bizutage",
      descriptionFre: "Souvent masqué comme une tradition, le bizutage est parfois difficile à identifier. Le fait que cela se soit produit dans le passé ne le rend pas acceptable aujourd'hui. Cette vidéo nous aide à identifier ce qu'est le bizutage et s'il se produit à votre égard. Nous proposons des solutions pour mettre fin à toute tolérance envers le bizutage. Ce n'est pas acceptable.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_02_Hazing_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID02FRE.png",
    },
    {
      titleEng: "Boundary Transgressions",
      descriptionEng: "Coaches and any other persons of authority must maintain professional boundaries with athletes. The video explains why this is important and how to identify red flags or warning signs that your boundaries are being crossed.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_03_Boundery Transgression_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID03ENG.png",
      titleFre: "Transgressions des limites",
      descriptionFre: "Les entraîneurs et toute autre personne ayant une autorité doivent maintenir des limites professionnelles avec les athlètes. La vidéo explique pourquoi c'est important et comment identifier les signaux d'alarme ou les signes avant-coureurs indiquant que vos limites sont franchies.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_03_Boundery Transgression_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID03FRE.png",
    },
    {
      titleEng: "Grooming",
      descriptionEng: "Grooming may be a new term for you. The video demonstrates common steps that can be used to groom youth and athletes so you can identify them in real life. We provide first-hand examples from survivors of grooming and explain how a groomer can try to manipulate athletes into going along with this form of abuse.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_04_Grooming_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID04ENG.png",
      titleFre: "Toilettage",
      descriptionFre: "Le toilettage peut être un nouveau terme pour vous. La vidéo montre les étapes communes qui peuvent être utilisées pour toiletter les jeunes et les athlètes afin que vous puissiez les identifier dans la vraie vie. Nous fournissons des exemples concrets de survivants du toilettage et expliquons comment un toiletteur peut essayer de manipuler les athlètes pour qu'ils acceptent cette forme d'abus.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_04_Grooming_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID04FRE.png",
    },
    {
      titleEng: "Discrimination, Psychological, and Physical Maltreatment",
      descriptionEng: "Hang in there, you are doing great. In the next two videos, we will cover the other forms of maltreatment we feel are important so keep those eyes and ears open, you’re almost there.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_05_Discrimination_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID05ENG.png",
      titleFre: "Discrimination, Maltraitance Psychologique et Physique",
      descriptionFre: "Accrochez-vous, vous vous en sortez très bien. Dans les deux prochaines vidéos, nous aborderons les autres formes de maltraitance que nous estimons importantes, alors gardez les yeux et les oreilles ouverts, vous y êtes presque.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_05_Discrimination_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID05FRE.png",
    },
    {
      titleEng: "Neglect, Sexual Maltreatment, and Micro-aggressions",
      descriptionEng: "Maltreatment often starts with subtle micro-aggressions, which can be harder to identify. Let's dig into what those are along with two more forms of maltreatment some athletes may encounter.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_06_Neglect_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID06ENG.png",
      titleFre: "Négligence, Maltraitance Sexuelle et Micro-agressions",
      descriptionFre: "La maltraitance commence souvent par des micro-agressions subtiles, qui peuvent être plus difficiles à identifier. Explorons ce que sont ces micro-agressions ainsi que deux autres formes de maltraitance que certains athlètes peuvent rencontrer.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_06_Neglect_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID06FRE.png",
    },
    {
      titleEng: "Report and Support",
      descriptionEng: "Final video! And an important one as we will discuss how you can report maltreatment and get support. Recognizing maltreatment is key so that you can speak out and get the help and support you need. We hope you enjoyed these short informative videos and we wish you all the best on your athletic journey, wherever that takes you. Stay safe and speak out.",
      urlEng: "https://dqdi1yce51qjt.cloudfront.net/english-with-caption/Education_Course_07_Report_V6_SRT_English.mp4",
      posterEng: "../assets/thumbnails/VID07ENG.png",
      titleFre: "Signaler et Soutien",
      descriptionFre: "Dernière vidéo ! Et une vidéo importante car nous discuterons de la manière dont vous pouvez signaler la maltraitance et obtenir du soutien. Reconnaître la maltraitance est essentiel pour que vous puissiez vous exprimer et obtenir l'aide et le soutien dont vous avez besoin. Nous espérons que vous avez apprécié ces courtes vidéos informatives et nous vous souhaitons tout le meilleur dans votre parcours sportif, où que cela vous mène. Restez en sécurité et parlez.",
      urlFre: "https://dqdi1yce51qjt.cloudfront.net/french-with-caption/Education_Course_07_Report_V6_SRT_French.mp4",
      posterFre: "../assets/thumbnails/VID07FRE.png",
    },
  ];

export { API,videoData };